import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SectionWaiting from '../components/SectionWaiting'
import { File, Handshake, Paid } from '../components/svg'

export default function TrueValue() {
    return (
        <>
            <div className='perfect-body'>
                <div className='container'>
                    <Header />
                    <div className='mt-100 pb-30'>
                        <div className='row justify-content-between'>
                              <div className='col-lg-4'>
                                    <div className='d-flex flex-column justify-content-between h-100'>
                                          <div>
                                                <span className='small-bas-heading color-purplr'>Your True Cash Offer!</span>
                                                <h1 className='main-heading color-dark mb-0 mt-20'>$23,025</h1>
                                          </div>
                                          <div>
                                                <p className='mb-0 color-dark-light opacity-75 text-capitalize'>No need to take any action - you’ll hear from the
                                                dealers below shortly.</p>
                                                <p className='mb-0 color-dark-light opacity-75 mt-20 text-capitalize'>Offer subject to dealer verification
                                                      <br/>
                                                <span className='font_bolder'>Expires 12/05/2024 10:13 PM PST.</span></p>
                                                <button className='download_btn'>DOWNLOAD PRINT</button>
                                          </div>
                                    </div>
                              </div>
                              <div className='col-lg-7'>
                                    <div className='car_value'>
                                          <img src="/assets/img/home/car_value.webp" alt="" className='w-100' />
                                    </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='blog-section'>
                  <div className='container'>
                  <h2 className='small-bas-heading color-purplr mb-0'>What happens Next?</h2>
                        <div className='blog-grid mt-30'>
                              <div className='value-card bg-purple'>
                                    <div className='d-flex align-items-center gap-3'>
                                          <Handshake/>
                                          <h2 className='value-title'>Connect with Dealer</h2>
                                    </div>
                                    <div>
                                          <p className='card_pera'>The dealer will reach out to confirm your car’s condition.</p>
                                    </div>
                              </div>
                              <div className='value-card bg-yellow'>
                                    <div className='d-flex align-items-center gap-3'>
                                          <File/>
                                          <h2 className='value-title color-dark'>Gather your Paperwork</h2>
                                    </div>
                                    <div>
                                          <p className='card_pera color-dark'>This Includes your car title and any loan
                                          documents</p>
                                    </div>
                              </div>
                              <div className='value-card bg-green'>
                                    <div className='d-flex align-items-center gap-3'>
                                          <Paid/>
                                          <h2 className='value-title color-dark'>Connect with Dealer</h2>
                                    </div>
                                    <div>
                                          <p className='card_pera color-dark'>The dealer will reach out to confirm your car’s condition.</p>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
            <div className='section_dealer'>
                  <div className='container'>
                        <div className='text-center'>
                              <span className='small-bas-heading color-purplr mb-0'>Your True Cash Offer!</span>
                              <h2 className='big-middel-heading color-dark mt-20 mb-0'>Your Connected Dealers</h2>
                              <p className='color-dark opacity-75 mb-0 mt-20'>These Dealers are ready to buy car and will reach out to you soon.</p>
                        </div>
                        <div className='blog-grid mt-60'>
                              <div className='dealer_card'>
                                    <img src="/assets/img/about/map.webp" alt="map" className='w-100' />
                                    <div>
                                          <div className='d-flex align-items-center'>
                                                <span className='border-h'></span>
                                                <span className='border-h m-0'></span>
                                                <h3 className='dealer_title mb-0 ps_5'>Pacific auto center - costa mesa</h3>
                                          </div>
                                          <div className='d-flex align-items-center gap-3 mt-20'>
                                                <div className='dealer_add'>
                                                      <span className='mb-1'>Address:</span>
                                                      <p className='mb-0'>2524 Harbart Bwd Costa
                                                      Mesa, CA 92626</p>
                                                </div>
                                                <div className='border-horizontal'></div>
                                                <div className='dealer_add'>
                                                      <span className='mb-1'>Steve cunninghan</span>
                                                      <p className='mb-0'>Sales Manager:
                                                      (800) 519-1511</p>
                                                </div>
                                          </div>                                          
                                    </div>
                                    <div>
                                          <a href="#" className='gray-btn border-0 btn-dealer w-100 text-center'>
                                                <span className='w-100'>See Dealer investory</span>
                                          </a>
                                    </div>

                              </div>
                              <div className='dealer_card'>
                                    <img src="/assets/img/about/map.webp" alt="map" className='w-100' />
                                    <div>
                                          <div className='d-flex align-items-center'>
                                                <span className='border-h'></span>
                                                <span className='border-h m-0'></span>
                                                <h3 className='dealer_title mb-0 ps_5'>Pacific auto center - costa mesa</h3>
                                          </div>
                                          <div className='d-flex align-items-center gap-3 mt-20'>
                                                <div className='dealer_add'>
                                                      <span className='mb-1'>Address:</span>
                                                      <p className='mb-0'>2524 Harbart Bwd Costa
                                                      Mesa, CA 92626</p>
                                                </div>
                                                <div className='border-horizontal'></div>
                                                <div className='dealer_add'>
                                                      <span className='mb-1'>Steve cunninghan</span>
                                                      <p className='mb-0'>Sales Manager:
                                                      (800) 519-1511</p>
                                                </div>
                                          </div>                                          
                                    </div>
                                    <div>
                                          <a href="#" className='gray-btn border-0 btn-dealer w-100 text-center'>
                                                <span className='w-100'>See Dealer investory</span>
                                          </a>
                                    </div>

                              </div>
                              <div className='dealer_card'>
                                    <img src="/assets/img/about/map.webp" alt="map" className='w-100' />
                                    <div>
                                          <div className='d-flex align-items-center'>
                                                <span className='border-h'></span>
                                                <span className='border-h m-0'></span>
                                                <h3 className='dealer_title mb-0 ps_5'>Pacific auto center - costa mesa</h3>
                                          </div>
                                          <div className='d-flex align-items-center gap-3 mt-20'>
                                                <div className='dealer_add'>
                                                      <span className='mb-1'>Address:</span>
                                                      <p className='mb-0'>2524 Harbart Bwd Costa
                                                      Mesa, CA 92626</p>
                                                </div>
                                                <div className='border-horizontal'></div>
                                                <div className='dealer_add'>
                                                      <span className='mb-1'>Steve cunninghan</span>
                                                      <p className='mb-0'>Sales Manager:
                                                      (800) 519-1511</p>
                                                </div>
                                          </div>                                          
                                    </div>
                                    <div>
                                          <a href="#" className='gray-btn border-0 btn-dealer w-100 text-center'>
                                                <span className='w-100'>See Dealer investory</span>
                                          </a>
                                    </div>

                              </div>
                        </div>
                  </div>
            </div>
            <SectionWaiting />
            <Footer />
        </>
    )
}
